<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-tabs v-model="tabActive" type="card" @tab-click="handleClick">
          <el-tab-pane label="待办任务" name="1"></el-tab-pane>
          <el-tab-pane label="已办任务" name="2"></el-tab-pane>
          <el-tab-pane label="我的发起" name="3"></el-tab-pane>
        </el-tabs>
      </template>
      <template v-if="tabActive == 1">
        <todo-list />
      </template>
      <template v-if="tabActive == 2">
        <done-list />
      </template>
      <template v-if="tabActive == 3">
        <my />
      </template>
    </common-layout>
  </div>
</template>
<script>
import todoList from './components/todoList'
import doneList from './components/doneList'
import my from './components/my'
export default {
  components: { todoList, doneList, my },
  data() {
    return {
      tabActive: '1',
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
  },
}
</script>
