<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-form inline>
          <el-form-item label="表单号">
            <el-input v-model="form.query.businessKey" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="query">查询</el-button>
          </el-form-item>
        </el-form>
      </template>
      <template #default>
        <el-table :data="tableData" v-loading="tableLoading" border height="100%">
          <el-table-column prop="businessKey" label="表单号" align="center" />
          <el-table-column prop="processName" label="流程标题" align="center" />
          <el-table-column prop="processModelName" label="流程模型" align="center" />
          <el-table-column prop="auditStatus" label="流程状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.auditStatus == 'pass'">通过</span>
              <span v-else-if="scope.row.auditStatus == 'reject'">拒绝</span>
              <span v-else>{{ scope.row.auditStatus }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="comment" label="审核意见" align="center" />
          <el-table-column prop="assignee" label="当前处理人" align="center" />
          <el-table-column prop="createTime" label="创建时间" align="center" />
          <el-table-column label="操作" align="center" width="120px" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleToFlow(scope.row)"> 跟踪 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <el-pagination class="mt10" :page-size="page.size" :current-page="page.current" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
      </template>
    </common-layout>
  </div>
</template>
<script>
import api from '@/api/operate/flowMgt/personWork'
export default {
  data() {
    return {
      form: {
        query: {},
      },
      tableData: [],
      tableLoading: false,
      page: {
        current: 1,
        size: 20,
      },
      total: 0,
    }
  },
  mounted() {
    this.query()
  },
  methods: {
    query() {
      let params = {
        ...this.form,
        ...this.page,
      }
      this.tableLoading = true
      api
        .getMyList(params)
        .then(({ data }) => {
          this.tableData = data.records
          this.total = data.total
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleSizeChange(val) {
      this.page.size = val
      this.page.current = 1
      this.query()
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.query()
    },
    handleToFlow(row) {
      window.open(`http://flow-portal.mokihi.com/#/form/trace?id=${row.processInstanceId}&Tenant=appstore-service`)
    },
  },
}
</script>
