<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-form inline>
          <el-form-item label="表单号">
            <el-input v-model="form.selectParam.businessKey" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="流程实例ID">
            <el-input v-model="form.selectParam.processInstanceId" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="query">查询</el-button>
          </el-form-item>
        </el-form>
      </template>
      <template #default>
        <el-table :data="tableData" v-loading="tableLoading" border height="100%">
          <el-table-column prop="businessKey" label="表单号" align="center" />
          <el-table-column prop="processInstanceId" label="流程实例ID" align="center" />
          <el-table-column prop="processName" label="流程标题" align="center" />
          <el-table-column prop="processModelName" label="流程模型" align="center" />
          <el-table-column prop="startUserId" label="发起人" align="center" />
          <el-table-column prop="taskDefName" label="处理节点" align="center" />
          <el-table-column prop="assignee" label="当前处理人" align="center" />
          <el-table-column prop="createTime" label="创建时间" align="center" />
          <el-table-column label="操作" align="center" width="120px" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleToFlow(scope.row)"> 跟踪 </el-button>
              <el-button type="text" @click="handleSubmit(scope.row)"> 审批 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <el-pagination class="mt10" :page-size="page.size" :current-page="page.current" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
      </template>
    </common-layout>
    <el-dialog title="审批" width="450px" :visible="showSubmit" @close="closeSubmit" :close-on-click-modal="false">
      <el-form label-width="70px" :model="submitForm" :rules="rules" ref="form">
        <el-form-item label="操作" prop="auditStatus">
          <el-radio-group v-model="submitForm.auditStatus">
            <el-radio label="pass">通过</el-radio>
            <el-radio label="reject">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="审核意见" prop="comment">
          <el-input placeholder="请输入" v-model="submitForm.comment" clearable type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="closeSubmit">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import api from '@/api/operate/flowMgt/personWork'
export default {
  data() {
    return {
      form: {
        selectParam: {},
      },
      tableData: [],
      tableLoading: false,
      page: {
        current: 1,
        size: 20,
      },
      total: 0,
      showSubmit: false,
      submitForm: {
        auditStatus: 'pass',
      },
      selectRow: null,
      rules: {
        auditStatus: [{ required: true, message: '', trigger: 'blur' }],
        comment: [{ required: true, message: '请输入审核意见', trigger: 'blur' }],
      },
    }
  },
  mounted() {
    this.query()
  },
  methods: {
    query() {
      let params = {
        ...this.form,
        ...this.page,
      }
      this.tableLoading = true
      api
        .getTodoList(params)
        .then(({ data }) => {
          this.tableData = data.records
          this.total = data.total
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleSizeChange(val) {
      this.page.size = val
      this.page.current = 1
      this.query()
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.query()
    },
    handleToFlow(row) {
      window.open(`http://flow-portal.mokihi.com/#/form/trace?id=${row.processInstanceId}&Tenant=appstore-service`)
    },
    handleSubmit(row) {
      this.selectRow = _.cloneDeep(row)
      this.$refs['form']?.resetFields()
      this.showSubmit = true
    },
    submit() {
      const params = {
        ...this.submitForm,
        taskId: this.selectRow.taskId,
        processInstanceId: this.selectRow.processInstanceId,
        type: 'APP_OPEN',
      }
      api.submit(params).then(() => {
        this.$message.success('操作成功')
        this.showSubmit = false
        this.query()
      })
    },
    closeSubmit() {
      this.showSubmit = false
    },
  },
}
</script>
